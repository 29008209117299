/*
 * @Author:liyao
 * @Date: 2021-05-31 14:09:13
 * @LastEditTime: 2021-06-08 14:39:40
 * @LastEditors: Please set LastEditors
 * @Description: 滑动
 * @FilePath: \hxb-companyd:\workplace\qy_wx_h5\src\directive\slide.js
 */
import { nextTick } from 'vue';
import IScroll from '@/utils/iscroll';
import { outerWidth } from '@/utils/comOperTools';
// el必须有id，
// 此处设置ul的长度实现滚动
// 外层wrapper设置css：width:100%;position:relative;overflow:hidden
// 如下：
{
  /* <div id="wrapper">
    <ul>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
    </ul>
</div> */
}
// pc端滑动指令
export const slide = {
  // 写在updated中，适用于异步数据
  updated(el, binding) {
    if (!binding.arg || binding.arg !== 'sync') {
      hanldeScroll(el);
    }
  },
  // 写在mounted中，binding.arg传sync，适用于同步数据
  mounted(el, binding) {
    if (binding.arg === 'sync') {
      hanldeScroll(el);
    }
  },
};
const hanldeScroll = (el) => {
  const element = `#${el.id}`;
  // 获取el下的第一个元素
  const child = el.firstChild;
  const childs = child.children;
  let totalWidth = 10;
  childs.forEach((element) => {
    totalWidth += outerWidth(element);
  });
  // console.log(totalWidth)
  child.style.width = totalWidth + 'px';

  // 获取子元素列表
  nextTick(() => {
    new IScroll(element, {
      mouseWheel: true,
      scrollX: true,
      scrollY: false,
      click:true
    });
  });
};
